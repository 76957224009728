//TODO REVISAR este y sus componentes hijos

class customerGiftsPage extends Component {

    static name() {
        return "customerGiftsPage";
    }

    static componentName() {
        return "customerGiftsPage";
    }

    goBack(){
        this.$router.go(-1);
    }

    getMethods(){
        return {
            goBack:this.goBack
        };
    }

    getTemplate(){
        return `<div class="row">
                  <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer">
                      <template v-if="$store.getters.getAppState=='ready'">
                          <registerModalComponent></registerModalComponent>
                          <loginPage></loginPage>
                          <div class="row">
                              <div class="col m2 s12">
                                  <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                              </div>                                
                              <div class="col m8 s12">
                                  <customerGiftsListComponent></customerGiftsListComponent>
                              </div>
                              <div class="col m2 s12">
                                  <userSectionComponent></userSectionComponent>
                                  <carComponent></carComponent>
                              </div>
                          </div>
                      </template>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

customerGiftsPage.registerComponent();